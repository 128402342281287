import React, { useMemo } from "react";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Container,
  Divider,
  Typography,
} from "@mui/material";
import { COLORS } from "constants/colors";
import { Link, Outlet, useNavigate, useParams } from "react-router-dom";
import { usePolls } from "../hooks";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

const PollPreview = ({ id, question, startDate }) => {
  return (
    <Box as="li">
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        position="relative"
        py={2}
        px={2}
        sx={{
          borderRadius: "4px",
        }}
      >
        <Box display="flex" flexDirection="row" alignItems="center">
          <Box display="flex" flexDirection="column">
            <Link to={`/polls/${id}`}>
              Week of {dayjs(startDate).format("MMM DD 'YY")}
            </Link>
            <Box as="small" whiteSpace="nowrap">
              {question}
            </Box>
          </Box>
        </Box>
      </Box>
      <Divider />
    </Box>
  );
};

export const PollsList = () => {
  const navigate = useNavigate();
  const { pollId } = useParams();
  const { data, isLoading } = usePolls();
  const polls = useMemo(() => {
    return data?.polls ?? undefined;
  }, [data]);
  const pastPolls = useMemo(() => {
    return (
      polls?.filter((poll) => dayjs(poll.endDate).isBefore(dayjs())) ??
      undefined
    );
  }, [polls]);
  const currentPoll = useMemo(() => {
    return polls?.find((poll) => dayjs(poll.endDate).isSameOrAfter(dayjs()) && dayjs(poll.startDate).isSameOrBefore(dayjs())) ??
      undefined;
  }, [polls]);
  const futurePolls = useMemo(() => {
    return (
      polls
        ?.filter((poll) => dayjs(poll.endDate).isSameOrAfter(dayjs()) && poll.id !== currentPoll?.id) ?? undefined
    );
  }, [polls, currentPoll?.id]);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        flex: 1,
        width: "100%",
      }}
    >
      <Box
        sx={{
          position: "sticky",
          top: 0,
          padding: "4px",
          display: "flex",
          height: "100vh",
          minWidth: "330px",
          overflowY: "hidden",
          flexDirection: "column",
          width: "20vw",
        }}
      >
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            backgroundColor: COLORS.WHITE,
            borderRadius: "4px",
            position: "relative",
            overflowY: "scroll",
            boxShadow: `inset 0px 0px 0px 1px ${COLORS.LIGHT_GRAY}`,
          }}
          pb={2}
          px={2}
        >
          <Box
            sx={{
              position: "sticky",
              top: 1,
              zIndex: 1,
              backgroundColor: COLORS.WHITE,
            }}
          >
            <Box
              sx={{
                position: "relative",
                backgroundColor: COLORS.WHITE,
                flex: 1,
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Typography variant="h2" sx={{ paddingTop: 1, paddingBottom: 2 }}>
                Poll Management
              </Typography>
              <Button
                sx={{ position: "absolute", right: 0 }}
                size="small"
                variant="contained"
                onClick={() => {
                  navigate("/polls/new");
                }}
              >
                +
              </Button>
            </Box>
          </Box>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <>
              <Typography variant="h3">Current Poll</Typography>
              <Box display="flex" flexDirection="column" as="ul">
                {currentPoll ? (
                  <Box position="relative">
                    <PollPreview {...currentPoll} />
                    <Box
                      position="absolute"
                      top={12}
                      right={4}
                      sx={{
                        transform: "scale(0.8)",
                      }}
                    >
                      <Chip
                        label="Live"
                        size="small"
                        sx={{
                          backgroundColor: COLORS.SUCCESS,
                        }}
                      />
                    </Box>
                  </Box>
                ) : (
                  <Typography sx={{ pl: 2 }}>
                    No poll scheduled for the current week.
                  </Typography>
                )}
              </Box>
              <Typography variant="h3">Upcoming Polls</Typography>
              <Box display="flex" flexDirection="column" as="ul">
                {futurePolls?.length ? (
                  futurePolls?.map((poll) => {
                    return <PollPreview {...poll} key={poll.id} />;
                  })
                ) : (
                  <Typography sx={{ pl: 2 }}>
                    No future polls scheduled at this time.
                  </Typography>
                )}
              </Box>
              <Typography variant="h3">Past Polls</Typography>
              <Box
                display="flex"
                flexDirection="column"
                as="ul"
              >
                {pastPolls ? (
                  pastPolls.map((poll) => {
                    return <PollPreview {...poll} key={poll.id} />;
                  })
                ) : (
                  <Typography>No past polls</Typography>
                )}
              </Box>
            </>
          )}
        </Box>
      </Box>
      <Container>
        <Outlet key={pollId} />
      </Container>
    </Box>
  );
};
