import { RubricsChecks } from "./constants";

const escapeRegex = (string) => {
  // Escape special characters in a regex string
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
};

const checkForTextMatch = ({
  textToCheck,
  termsCaseSensitive,
  termsCaseInsensitive,
}) => {
  const caseInsensitiveRegex = new RegExp(
    termsCaseInsensitive.split("|").map(escapeRegex).join("|"),
    "i"
  );
  const caseInsensitiveMatches = textToCheck.match(caseInsensitiveRegex);

  if (termsCaseSensitive) {
    const caseSensitiveRegex = new RegExp(
      termsCaseSensitive.split("|").map(escapeRegex).join("|")
    );
    const caseSensitiveMatches = textToCheck.match(caseSensitiveRegex);
    return caseInsensitiveMatches?.length > 0 || caseSensitiveMatches?.length > 0;
  } else {
    return caseInsensitiveMatches?.length > 0;
  }
};

export const getTextMatchResults = ({ selectedRubric, textToCheck }) => {
  if (!selectedRubric) {
    return null;
  }
  let results = [];
  for (const check of RubricsChecks[selectedRubric]) {
    results.push({
      id: check.id,
      question: check.question,
      answer: checkForTextMatch({
        textToCheck,
        termsCaseSensitive: check.termsCaseSensitive,
        termsCaseInsensitive: check.termsCaseInsensitive,
      }),
    });
  }
  return results;
};
