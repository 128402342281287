"use strict";

const PUBLISHED = "PUBLISHED";
const DRAFT = "DRAFT";
const ARTICLE = "ARTICLE";
const CLASS = "CLASS";

const CONTENT_TYPES = [ARTICLE, CLASS];
const CONTENT_STATUS = [PUBLISHED, DRAFT];

module.exports = {
  ARTICLE,
  PUBLISHED,
  DRAFT,
  CLASS,
  CONTENT_STATUS,
  CONTENT_TYPES,
};
