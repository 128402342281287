export const Rubrics = {
  CHEMICAL: "chemical",
  TEXTILE: "textile",
};

export const RubricsChecks = {
  [Rubrics.CHEMICAL]: [
    {
      id: 1,
      question: "Contains parabens?",
      termsCaseInsensitive: process.env.REACT_APP_CHEMICAL_1_TERMS_CASE_INSENSITIVE || "Dolly Parton",
      termsCaseSensitive: process.env.REACT_APP_CHEMICAL_1_TERMS_CASE_SENSITIVE,
    },
    {
      id: 2,
      question: "Contains phthalates?",
      termsCaseInsensitive: process.env.REACT_APP_CHEMICAL_2_TERMS_CASE_INSENSITIVE || "Dolly Parton",
      termsCaseSensitive: process.env.REACT_APP_CHEMICAL_2_TERMS_CASE_SENSITIVE,
    },
    {
      id: 3,
      question: "Contains petrochemical?",
      termsCaseInsensitive: process.env.REACT_APP_CHEMICAL_3_TERMS_CASE_INSENSITIVE || "Dolly Parton",
      termsCaseSensitive: process.env.REACT_APP_CHEMICAL_3_TERMS_CASE_SENSITIVE,
    },
    {
      id: 4,
      question: "Contains PFAS?",
      termsCaseInsensitive: process.env.REACT_APP_CHEMICAL_4_TERMS_CASE_INSENSITIVE || "Dolly Parton",
      termsCaseSensitive: process.env.REACT_APP_CHEMICAL_4_TERMS_CASE_SENSITIVE,
    },
    {
      id: 5,
      question: "Contains palm oil?",
      termsCaseInsensitive: process.env.REACT_APP_CHEMICAL_5_TERMS_CASE_INSENSITIVE || "Dolly Parton",
      termsCaseSensitive: process.env.REACT_APP_CHEMICAL_5_TERMS_CASE_SENSITIVE,
    },
  ]
};
