import React, { useState } from "react";
import { Typography } from "@mui/material";
import Selector from "./Selector";
import Picklist from "./Picklist";
import { useProductTypeQuery, useBrandsByProductTypeQuery, useBrandsQuery, useProductTypeCRUDMutation, useProductTypeAssociationMutation } from "../hooks";
import { Modal, Box, TextField, Button } from "@mui/material";

const brandSort = (brandA, brandB) => {
  if (brandA.name < brandB.name) { return -1; }
  if (brandA.name > brandB.name) { return 1; }
  return 0;
};

const formatBrandName = (brand) => {
  const score = brand?.rubricScore?.score;
  const brandName = brand?.Vendor?.vendorName;
  if (score === undefined || score === null) {
    return brandName;
  } else {
    const stars = Math.round(score);
    const suffix = "★".repeat(stars) + "☆".repeat(5 - stars);
    return `${brand?.Vendor?.vendorName} (${suffix})`;
  }
};

const BrandsByProductType = () => {

  const [selectedProductType, setSelectedProductType] = useState(null);
  const [productTypeName, setProductTypeName] = useState("");

  const onSetSelected = (id) => {
    setSelectedProductType(id);
  };

  const { isLoading: productQueryPending, error: productQueryError, data: productData } = useProductTypeQuery();
  const { data: brandsData } = useBrandsByProductTypeQuery(selectedProductType);
  const { data: allBrandsData } = useBrandsQuery();
  const { create: createProductType, delete: deleteProductType } = useProductTypeCRUDMutation();
  const { create: associate, delete: dissociate } = useProductTypeAssociationMutation();
  const [openEntryDialog, setOpenEntryDialog] = useState(false);

  const associateProductTypeWithBrand = async (brandId) => {
    await associate.mutateAsync({
      brandId,
      productTypeId: selectedProductType
    });
  };

  const dissociateProductTypeFromBrand = async ({ id: brandId }) => {
    await dissociate.mutateAsync({ brandId, productTypeId: selectedProductType });
  };

  const onSave = async () => {
    await createProductType.mutateAsync(productTypeName);
    setOpenEntryDialog(false);
  };

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const handleConfirmDelete = async () => {
    await deleteProductType.mutateAsync(selectedProductType);
    setSelectedProductType(null);
    setOpenConfirmDialog(false);
  };

  const remove = () => {
    setOpenConfirmDialog(true);
  };

  const productTypes = productData?.productTypes ?? [];
  productTypes.sort((a, b) => a.name.localeCompare(b.name));

  const selectedBrands = brandsData?.brands.map((pbi) => ({
    id: pbi.brandId,
    name: formatBrandName(pbi.Brand),
    relationshipId: pbi.id
  })) ?? [];
  selectedBrands.sort(brandSort);

  const availableBrands = selectedProductType ? allBrandsData?.brands?.filter((brand) => !selectedBrands.find((sb) => sb.id === brand.id)).map((brand) => ({
    id: brand?.id,
    name: formatBrandName(brand)
  })) ?? [] : [];
  availableBrands.sort(brandSort);

  return (
    <>
      <Box display="flex" flexDirection="row" gap={2}>
        <Box flex={1}>
          {productTypes.length &&
          <Selector
            title="Product Types"
            options={productTypes}
            onSetSelected={onSetSelected}
            onAdd={() => { setOpenEntryDialog(true); }}
            onSubtract={remove}
          />}
          {
            productQueryPending && <Typography variant="h6">Loading...</Typography>
          }
          {
            productQueryError && <Typography variant="h6">Error: {productQueryError.message}</Typography>
          }
        </Box>
        <Box flex={1}>
          <Picklist
            title="Brands"
            activeList={selectedBrands}
            availableList={availableBrands}
            onActiveItemClick={dissociateProductTypeFromBrand}
            onAvailableItemClick={associateProductTypeWithBrand}
          />
        </Box>
      </Box>
      <Modal open={openEntryDialog}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6" component="h2">
            Add Product Type
          </Typography>
          <TextField
            fullWidth
            label="Product Type Name"
            margin="normal"
            onChange={(e) => setProductTypeName(e.target.value)}
          />
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button onClick={() => { setOpenEntryDialog(false); }} sx={{ mr: 1 }}>
              Cancel
            </Button>
            <Button variant="contained" onClick={onSave}>
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal open={openConfirmDialog}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6" component="h2">
            Are you sure you want to delete this product type?
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button onClick={() => { setOpenConfirmDialog(false); }} sx={{ mr: 1 }}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleConfirmDelete}>
              Delete
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default BrandsByProductType;
