import React, { useState, useMemo } from "react";
import {
  Typography,
  TextareaAutosize,
  Box,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Grid,
} from "@mui/material";
import { getTextMatchResults } from "../utils";
import { Rubrics, RubricsChecks } from "../constants";
import { PageHeader, PageLayout, PageMain } from "components/layout/PageLayout";

export const IngredientChecker = () => {
  const [selectedRubric, setSelectedRubric] = useState("");
  const [textToCheck, setTextToCheck] = useState("");
  const results = useMemo(() => {
    return getTextMatchResults({ selectedRubric, textToCheck });
  }, [selectedRubric, textToCheck]);
  return (
    <PageLayout>
      <PageHeader>
        <Typography variant="h3">Ingredient/Materials Checker</Typography>
      </PageHeader>

      <PageMain>
        <Box display="flex" flexDirection="row">
          <Box sx={{ width: "40vw", mr: 2 }}>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel id="demo-simple-select-label">
                Step 1. Select Rubric
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedRubric}
                label="Rubric"
                onChange={(e) => setSelectedRubric(e.target.value)}
              >
                {Object.values(Rubrics).map((rubric) => {
                  const menuItemCopy = rubric.toUpperCase();
                  let menuItemCopyAppend = "";
                  let isDisabled = false;
                  if (!RubricsChecks[rubric]) {
                    isDisabled = true;
                    menuItemCopyAppend = " (coming soon!)";
                  }
                  return (
                    <MenuItem key={rubric} value={rubric} disabled={isDisabled}>
                      {menuItemCopy}
                      {menuItemCopyAppend}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            {selectedRubric ? (
              <FormControl fullWidth>
                <TextareaAutosize
                  onChange={(e) => setTextToCheck(e.target.value)}
                  value={textToCheck}
                  aria-label="empty textarea"
                  minRows={10}
                  width={"40vw"}
                  sx={{
                    border: "1px solid rgba(0, 0, 0, 0.23)",
                    borderRadius: 1,
                  }}
                  labelId="demo-simple-input-label"
                  id="demo-simple-input"
                  placeholder="Step 2. Paste ingredient or materials list here"
                />
              </FormControl>
            ) : null}
          </Box>
          {results ? (
            <Box sx={{ width: "30vw" }}>
              <Typography variant="h4">
                Step 3: Review relevant rubric questions
              </Typography>
              <Box
                component="ul"
                flex
                sx={{
                  width: "100%",
                  border: "1px solid rgba(0, 0, 0, 0.23)",
                  borderRadius: 1,
                }}
              >
                {results.map((result) => (
                  <Grid
                    container
                    component="li"
                    key={result.id}
                    flex
                    flexDirection="row"
                    justifyContent="space-between"
                    flexWrap={"nowrap"}
                    sx={{
                      backgroundColor:
                        result.id % 2 === 0 ? "#c7cfff" : "transparent",
                      padding: 1,
                    }}
                  >
                    <Grid item>
                      <Typography variant="body1">{result.question}</Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2">
                        {result.answer ? "yes" : "no"}
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
              </Box>
            </Box>
          ) : null}
        </Box>
      </PageMain>
    </PageLayout>
  );
};
