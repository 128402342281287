import { RubricsChecks } from "./constants";

const checkForTextMatch = ({ textToCheck, regex }) => {
  const pattern = new RegExp(`\\b(?:${regex})\\b`, "gi");
  return pattern.test(textToCheck);
};

export const getTextMatchResults = ({ selectedRubric, textToCheck }) => {
  if (!selectedRubric) {
    return null;
  }
  let results = [];
  for (const check of RubricsChecks[selectedRubric]) {
    results.push({
      id: check.id,
      question: check.question,
      answer: checkForTextMatch({ textToCheck, regex: check.regex }),
    });
  }
  return results;
};
