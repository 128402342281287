export const Rubrics = {
  CHEMICAL: "chemical",
  TEXTILE: "textile",
};

export const RubricsChecks = {
  [Rubrics.CHEMICAL]: [
    {
      id: 1,
      question: "Contains parabens?",
      regex: process.env.REACT_APP_CHEMICAL_1_REGEX || "Dolly Parton",
    },
    {
      id: 2,
      question: "Contains phthalates?",
      regex: process.env.REACT_APP_CHEMICAL_2_REGEX || "Dolly Parton",
    },
    {
      id: 3,
      question: "Contains petrochemical?",
      regex: process.env.REACT_APP_CHEMICAL_3_REGEX || "Dolly Parton",
    },
    {
      id: 4,
      question: "Contains PFAS?",
      regex: process.env.REACT_APP_CHEMICAL_4_REGEX || "Dolly Parton",
    },
    {
      id: 5,
      question: "Contains palm oil?",
      regex: process.env.REACT_APP_CHEMICAL_5_REGEX || "Dolly Parton",
    },
  ]
};
